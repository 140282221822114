import * as React from "react";
import Link from "next/link";
import { Player } from "@lottiefiles/react-lottie-player";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

import walkingAnimation from "../assets/404.json";
import { HeroBg, InfiniteSlider, Page } from "@/components";
import { TransitionContext } from "@/contexts/TransitionContext";
import { cn } from "@/lib/cn";

/**
 * Renders the 404 page
 * Shows a 404 page with a 404 error message
 */
const NotFoundPage = () => {
  const container = React.useRef<HTMLDivElement>(null);
  const [showSlider, setShowSlider] = React.useState(false);

  const { timeline } = React.useContext(TransitionContext);

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    const width = window.innerWidth;
    if (width > 1024) {
      setShowSlider(true);
    } else {
      setShowSlider(false);
    }
  };

  useGSAP(
    () => {
      gsap.fromTo(container.current, { opacity: 0 }, { opacity: 1 });
      timeline.add(gsap.to(container.current, { opacity: 0 }));
    },
    { scope: container }
  );

  return (
    <Page pageTitle="Tarun Sharma • 404">
      <div
        ref={container}
        className="relative flex h-screen flex-col items-center justify-center overflow-hidden"
      >
        <HeroBg className="-mt-[30%]" />
        {showSlider ? (
          <InfiniteSlider duration={50} gap={208}>
            {Array.from({ length: 5 }).map((_, index) => (
              <div
                key={index}
                className="flex items-center justify-center gap-x-52"
              >
                <h1 className="font-Acorn-Bold from-primary-900 via-primary-100 to-primary-100 bg-gradient-to-t bg-clip-text text-[15rem] tracking-tight text-transparent">
                  404
                </h1>
                <span className="font-Acorn-Bold from-primary-900 via-primary-100 to-primary-100 bg-gradient-to-t bg-clip-text text-[15rem] tracking-tight text-transparent">
                  NOT FOUND
                </span>
              </div>
            ))}
          </InfiniteSlider>
        ) : (
          <div className="px-10">
            <h1 className="font-Acorn-Bold from-primary-900 via-primary-100 to-primary-100 xs:text-[15rem] vs:text-[13rem] bg-gradient-to-t bg-clip-text text-[10rem] tracking-tight text-transparent">
              404
            </h1>
          </div>
        )}
        <div
          className={cn(
            "absolute inset-0 flex h-full w-full flex-col items-center justify-end gap-y-20 pb-10 md:gap-y-12 md:pb-8",
            {
              "justify-end gap-y-0 pb-24": !showSlider,
            }
          )}
        >
          <Player
            src={walkingAnimation}
            autoplay
            loop
            className="xs:h-[400px] xs:w-[400px] 3xl:h-[750px] 3xl:w-[750px] vs:h-[400px] vs:w-[400px] h-[370px] w-[370px] md:h-[450px] md:w-[450px] lg:h-[500px] lg:w-[500px] xl:h-[550px] xl:w-[550px] 2xl:h-[650px] 2xl:w-[650px]"
          />
          {showSlider ? (
            <div className="flex flex-col items-center justify-center gap-y-2 px-4">
              <p className="text-primary-300 vxs:max-w-md 3xl:max-w-lg 3xl:text-base text-center text-sm font-light uppercase tracking-tight">
                OOPS!{" "}
                <span className="3xl:text-sm text-xs">
                  Looks like that page
                </span>{" "}
                is missing{" "}
                <span className="3xl:text-sm text-xs">or has maybe </span> been
                moved <span className="3xl:text-sm text-xs"> It probably</span>{" "}
                wasn&apos;t that great
                <span className="3xl:text-sm text-xs"> anyway</span> Don&apos;t
                worry
              </p>
            </div>
          ) : null}
        </div>
        <div className="absolute left-0 right-0 top-8 flex flex-col items-center justify-center gap-2">
          <Link
            href="/"
            className="text-primary-100 active:cursor-active 3xl:text-base relative cursor-pointer text-center text-sm font-light tracking-tight"
          >
            RETURN TO HOME
          </Link>
        </div>
      </div>
    </Page>
  );
};

export default NotFoundPage;
